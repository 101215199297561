import React, { useState, useEffect } from 'react'

import BoxProcessoStatus from '../../BoxProcessoStatus'

import { Processos } from '../../../utils/Processo'
import Loading from '../../Loading'

const MeusProcessos = () => {
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(true)
    const [processos, setProcessos] = useState('')

    const paginadorState = {
        Pagina: 1,
        Qtd: 10
    }

    const [paginador, setPaginador] = useState(paginadorState)

    const carregarMais = (event) => {
        setLoading(true)
        setPaginador({
            ...paginador,
            Pagina: paginador.Pagina + 1
        })
        setLoading(false)
    }

    useEffect(() => {
        setProcessos(() => {
            const processos = Processos.slice(0, paginador.Pagina * paginador.Qtd)

            return processos.map((processo, index) => {
                return (
                    <BoxProcessoStatus key={index} processo={processo} />
                );
            });
        })

        if (Processos != null && Processos.length > 0) {
            const [processo] = Processos
 
            setNome(processo.PedidoDto.ClienteNome)
            setEmail(processo.PedidoDto.ClienteEmail)
            setLoading(false)
        }
    }, [paginador])

    return (
        <>
            <div className="colcol-sm-12 col-md-12 col-lg-4 pb-2">
                <div className="primary-box p-4">
                    <div className={loading ? 'text-center' : ''}>
                        <h2>Meus Dados</h2><hr />
                        <div>
                            <i className="fas fa-user" aria-hidden="true"></i> <span className="ml-1">{nome}</span><br />
                            <i className="fas fa-envelope" aria-hidden="true"></i> <span className="ml-1">{email}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`col-sm-12 col-md-12 col-lg-8 ${loading ? 'text-center pt-25' : ''}`}>
                {processos}
                {
                    (paginador.Pagina * paginador.Qtd) >= Processos.length
                        ? ''
                        : <div className='text-center'>
                            <button type="button" onClick={carregarMais}>Carregar Mais</button>
                        </div>
                }
                
            </div>
            {loading ? <Loading text='Carregando...' /> : ''}
        </>
    );
}

export default MeusProcessos