import React, { useState, useEffect } from 'react'
import ReactTooltip from "react-tooltip";
import * as moment from 'moment'

import BoxProcessoStatusProduto from './BoxProcessoStatusProduto'

const CorPendente = '#eb6709'
const CorConcluido = '#22366e'
const CorCancelado = '#ff4c52'

const BoxProcessoStatus = ({ processo }) => {

    const [painelClass, setpPanelClass] = useState('hide')

    const [inicio, setInicio] = useState(true)
    const [postado, setPostado] = useState(false)
    const [recebidoConferido, setRebidoConferido] = useState(false)
    const [estorno, setEstorno] = useState(false)
    const [produtosSolicitados, setProdutosSolicitados] = useState(false)
    const [concluido, setConcluido] = useState(false)
    const [troca, setTroca] = useState(false)
    const [textoEstorno, setTextoEstorno] = useState('')

    const { PedidoDto, Pendencias } = processo
    const { Skus } = PedidoDto

    const produtos = Skus.map((sku, index) => {
        if (sku.TipoProcesso === 1 && !troca) {
            setTroca(true)
        }

        return (
            <BoxProcessoStatusProduto key={index} sku={sku} processo={processo} />
        );
    });

    const corStatus = () => {
        if (processo.Status === 2) {
            return CorConcluido
        }

        if (processo.Status === 3) {
            return CorCancelado
        }

        return CorPendente
    }

    const handleProcessoClick = () => {
        if (painelClass === 'show') {
            return setpPanelClass('hide')
        }

        return setpPanelClass('show')
    }

    useEffect(() => {
        setInicio(true)

        const SetStep = () => {

            if (!Pendencias.PendenciaRating) {
                setPostado(true)
                setRebidoConferido(true)
            }

            if (processo.Status === 2) {
                setPostado(true)
                setRebidoConferido(true)
                setEstorno(true)
                setProdutosSolicitados(true)
                setConcluido(true)
                return
            }

            if (!Pendencias.PendenciaProdutosEnviados) {
                if (Pendencias.PendenciaValeCompras || Pendencias.PendenciaEstorno) {
                    setPostado(true)
                    //setRebidoConferido(true)
                    return
                }

                setPostado(true)
                //setRebidoConferido(true)
                setEstorno(true)
                setProdutosSolicitados(true)
                return
            }

            if (!Pendencias.PendenciaValeCompras && !Pendencias.PendenciaEstorno) {
                setPostado(true)
                setRebidoConferido(true)
                setEstorno(true)
                return
            }

            //if (!Pendencias.PendenciaRating) {
            //    setPostado(true)
            //    setRebidoConferido(true)
            //    return
            //}

            if (!Pendencias.PendenciaProdutosEnviados) { //PendenciaPostagem
                //if (!Pendencias.PendenciaLogistica) { 
                    setPostado(true)
                    return
                //}
            }
        }
        const GetTextoEstorno = () => {
            let valorEstorno = '';

            if (processo.ValorEstornoCalculado != null && processo.ValorEstornoCalculado > 0) {
                const valor = new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                }).format(processo.ValorEstornoCalculado);

                valorEstorno = valor
            }

            if (processo.ValeCompra != null) {
                if (processo.ValeCompra.ValeCompraGerado
                    && processo.ValeCompra.RedemptionCode != null
                    && processo.ValeCompra.RedemptionCode.length > 0
                ) {
                    return `Vale Compras gerado (${processo.ValeCompra.RedemptionCode}) ${valorEstorno}`
                }

                return `Vale Compras ${valorEstorno}`
            }

            return `Estorno ${valorEstorno}`
        }

        setTextoEstorno(GetTextoEstorno())
        SetStep()
    }, [inicio, postado, recebidoConferido, estorno, produtosSolicitados, concluido, troca, textoEstorno, processo, Skus, Pendencias])

    return (
        <div className='box-processos'>
            <div className="processo-item" style={{ borderLeftColor: corStatus() }}>
                <div className="d-flex box-processo-item">
                    <div className="w-100">
                        <div className="row box-processo-head py-10" onClick={handleProcessoClick}>
                            <div className="col-sm-12 col-md-4 col-lg-5">
                                <i className={painelClass === 'show' ? 'fas fa-angle-up' : 'fas fa-angle-down'}></i>
                                <span style={{ color: corStatus() }}>{ processo.descStatus }</span>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 text-right text-md-left">
                                { processo.Numero }
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-3 text-right">
                                { moment(processo.Data).format("DD/MM/YYYY hh:mm") }
                            </div>
                        </div>
                        <div className={`row box-processo-pedido pt-3 ${painelClass}`}>
                            <div className="col-lg-8 col-md-8 col-sm-7 font-weight-bold">Pedido</div>
                            <div className="col-lg-4 col-md-4 col-sm-5 font-weight-bold">Data Pedido</div>

                            <div className="col-lg-8 col-md-8 col-sm-7">{PedidoDto.Numero}</div>
                            <div className="col-lg-4 col-md-4 col-sm-5"> {moment(PedidoDto.Data).format("DD/MM/YYYY hh:mm")}</div>
                        </div>
                        {produtos}
                    </div>
                </div>
                <div className={`step-processo pt-5 ${painelClass}`}>
                    <div className="card align-items-center">
                        <ul>
                            <li id='step-inicio' className={inicio ? 'active' : ''} data-tip data-for="step-inicio-tooltip">
                                <ReactTooltip id="step-inicio-tooltip" place="top" effect="solid">
                                    Iniciado
                                </ReactTooltip>
                                <i className="fas fa-play rounded-circle"></i>
                                <span className='d-none d-md-none d-lg-block'>Iniciado</span>
                            </li>
                            <li id='step-postado' className={postado ? 'active' : ''} data-tip data-for="step-postado-tooltip">
                                <ReactTooltip id="step-postado-tooltip" place="top" effect="solid">
                                    Postado
                                </ReactTooltip>
                                <i className="fas as fa-archive rounded-circle"></i>
                                <span className='d-none d-md-none d-lg-block'>Postado</span>
                            </li>
                            <li id='step-recebido' className={recebidoConferido ? 'active' : ''} data-tip data-for="step-recebido-tooltip">
                                <ReactTooltip id="step-recebido-tooltip" place="top" effect="solid">
                                    Produto recebido e conferido
                                </ReactTooltip>
                                <i className="fas fa-clipboard-check rounded-circle"></i>
                                <span className='d-none d-md-none d-lg-block'>Produto recebido e conferido</span>
                            </li>
                            <li id='step-estorno' className={estorno ? 'active' : ''} data-tip data-for={`step-estorno-tooltip-${processo.Numero}`}>
                                <ReactTooltip id={`step-estorno-tooltip-${processo.Numero}`} place="top" effect="solid">
                                    {textoEstorno}
                                </ReactTooltip>
                                <i className="fas fa-dollar-sign rounded-circle"></i>
                                <span className='d-none d-md-none d-lg-block'>{textoEstorno}</span>
                            </li>
                            {
                                troca
                                    ? <li id='step-enviados' className={produtosSolicitados ? 'active' : ''} data-tip data-for="step-enviados-tooltip">
                                        <ReactTooltip id="step-enviados-tooltip" place="top" effect="solid">
                                            Produtos solicitados enviados
                                        </ReactTooltip>
                                        <i className="fas fa-truck rounded-circle"></i>
                                        <span className='d-none d-md-none d-lg-block'>Produtos solicitados enviados</span>
                                    </li>
                                    : ''
                            }
                            <li id='step-concluido' className={concluido ? 'active' : ''} data-tip data-for="step-concluido-tooltip">
                                <ReactTooltip id="step-concluido-tooltip" place="top" effect="solid">
                                    Concluído﻿﻿
                                </ReactTooltip>
                                <i className="fas fa-check rounded-circle"></i>
                                <span className='d-none d-md-none d-lg-block'>Concluído﻿﻿</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BoxProcessoStatus