import React from 'react';

const Loading = ({ text }) => {

    return (
        <div id='loading'>
            <div className='m-auto'>
                <h1>{text}</h1>
                <div className="lds-circle">
                    <div></div>
                </div>
            </div>
        </div>
    );
}

export default Loading