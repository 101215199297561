const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
}

export const RequestGetOpcoes = {
    method: 'GET',
    ...headers,
}

export const RequestPostOpcoes = (params) => {
    return {
        method: 'POST',
        ...headers,
        body: JSON.stringify(params)
    }
}

export const ObjetoParaQueryString = (objeto) => {
    return Object.keys(objeto)
        .map(key => key + '=' + objeto[key])
        .join('&')
}