import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { RequestPostOpcoes } from '../../../utils/Request'
import { Alias, Config, SetEmail, SetAlias, SetConfig } from '../../../utils/Usuario'
import { SetProcessos } from '../../../utils/Processo'
import { RequestGetOpcoes, ObjetoParaQueryString } from '../../../utils/Request'

import VariaveisEstilo from '../../VariaveisEstilo'
import Loading from '../../Loading'

const preventDefault = f => e => {
    e.preventDefault()
    f(e)
}

const Login = () => {
    const search = useLocation().search
    const aliasName = new URLSearchParams(search).get('alias');

    const [infosValidas, setInfosValidas] = useState(true)
    const [Email, setEmail] = useState('')
    const [mensagem, setMensagem] = useState('')
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [configOk, setConfigOk] = useState(true)

    const handleClose = () => setShow(false)

    const handleParam = (setValue) => e => {
        e.preventDefault()
        setValue(e.target.value)
    }

    const handleSubmit = preventDefault(() => {
        setLoading(true)
        if (Email === '' || Email === undefined) {
            setShow(true)
            setLoading(false)
            return setMensagem("Informe um e-mail para prosseguir.")
        }

        if (Config == null || Config.cliente == null || Config.cliente.idProtected == null) {
            setShow(true)
            setLoading(false)
            return setMensagem("Não foi possível encontrar o cliente.")
        }

        const params = {
            clienteId: Config.cliente.idProtected,
            email: Email
        }

        fetch('/api/processo/CarregarPorEmail'
            , RequestPostOpcoes(params))
            .then(response => response.json())
            .then(data => {
                if (data.erro) {
                    setShow(true)
                    setLoading(false)
                    return setMensagem(data.erro)
                }

                if (data.length === 0) {
                    setShow(true)
                    setLoading(false)
                    return setMensagem('Não há nenhuma solicitação vinculada ao e-mail informado.')
                }

                SetProcessos(data)
                SetEmail(Email)

                window.location.href = '/meus-processos';
                return
            })
            .catch(error => {
                console.log(error)
                setShow(true)
                setLoading(false)
                return setMensagem(error)
            });
    })

    useEffect(() => {
        if (Alias === null && Config === null) {
            setConfigOk(false)
        }

        if (Alias !== aliasName && aliasName) {
            setConfigOk(false)
        }

        if (!configOk) {
            setLoading(true)

            if (!aliasName) {
                setInfosValidas(false)
                return setLoading(false)
            }

            const params = {
                alias: aliasName
            }

            fetch('/api/login/ObterConfiguracao?' + ObjetoParaQueryString(params)
                , RequestGetOpcoes)
                .then(response => response.json())
                .then(data => {
                    if (data.erro) {
                        setMensagem(data.erro)
                        setShow(true);
                        setLoading(false)
                        return setInfosValidas(false)
                    }

                    if (data != null) {
                        SetAlias(aliasName)
                        SetConfig(data)
                        return window.location.reload()
                    }

                    return setLoading(false)
                })
                .catch(error => {
                    console.error('Ocorreu um erro: ', error);
                    setMensagem(error)
                    setShow(true);
                    return setLoading(false)
                });
        }

        VariaveisEstilo(Config)
    }, [infosValidas, mensagem, Email, show, configOk, aliasName])

    return (
        <><div className='primary-box intro p-5 mx-auto mb-5'>
            <h1>Bem vindo(a) à área do cliente</h1>
            <p className='mt-5'>Nesta área você poderá consultar o andamento de suas solicitações de troca ou devolução de produtos.</p>
            <p>Informe abaixo seu e-mail utilizado no pedido da loja virtual:</p>
            <div className='call-switcher mx-auto mt-5'>
                {infosValidas ?
                    (
                        <form onSubmit={handleSubmit}>
                            <div className="app form-clear">
                                <div>
                                    <div className='field filled'>
                                        <label htmlFor="email">Endereço de e-mail cadastrado</label>
                                        <input
                                            type='email'
                                            name='email'
                                            value={Email}
                                            onChange={handleParam(setEmail)} />
                                    </div>
                                </div>
                                <button type="submit">Localizar solicitações</button>
                            </div>
                        </form>
                    ) : (
                        <><div className="alert alert-warning">
                            Oops, a loja não foi encontrada.
                        </div>
                            <p>Entre em contato com a loja para verificar se o link fornecido está correto.</p>
                        </>
                    )}
            </div>
        </div><>
            <Modal show={show} centered>
                {/*<Modal.Header>*/}
                {/*    <Modal.Title>Oops</Modal.Title>*/}
                {/*</Modal.Header>*/}
                <Modal.Body>{mensagem}</Modal.Body>
                <Modal.Footer>
                    <Button className="small" size="sm" onClick={handleClose}>Ok</Button>
                </Modal.Footer>
            </Modal>
            {loading ? <Loading text='Carregando...' /> : ''}
        </></>
    );
}

export default Login