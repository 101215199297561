import React, { Component } from 'react'

import { Config, Alias, SetEmail } from '../../../utils/Usuario'
import { UrlLogo, UrlLogoS3, GetLogoUrl } from '../../../utils/Config'
import { Processos, SetProcessos } from '../../../utils/Processo'

export class LayoutMain extends Component {
    constructor(props) {
        super(props);

        let logoNome = UrlLogoS3 + UrlLogo

        if (Config != null && Config.cliente != null) {
            logoNome = GetLogoUrl(Config.cliente.arquivoLogo)
        }

        if (Processos === null || Processos.length === 0) {
            return window.location.href = '/?alias=' + Alias
        }

        this.state = {
            Logo: logoNome,
            RazaoSocial: Config.cliente.razaoSocial
        };
    }

    render() {
        const LogOut = () => {
            SetProcessos(null)
            SetEmail(null)
        }

        return (
            <><header>
                <div className='container'>
                    {/*BARRA DO TOPO APENAS PARA MOBILE e TABLET*/}
                    <div className='header-mobile d-block d-lg-none'>
                        <div className='row'>
                            <div className='col-6 d-flex align-items-center'>
                                <img src={this.state.Logo} alt={this.state.RazaoSocial} title={this.state.RazaoSocial} className='logo-cliente mx-auto' />
                            </div>
                            <div className='col-3 d-flex offset-2 align-items-center'>
                                <div className='w-100 text-right'>
                                    <a href={`/?alias=${Alias}`} role="button" onClick={LogOut} className='btn-voltar pull-rigth'>
                                        <i className="fas fa-power-off rounded-circle"></i>
                                        <span>Sair</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*BARRA DO TOPO PARA DESKTOP*/}
                    <div className='d-none d-md-none d-lg-block'>
                        <div className='row'>
                            <div className='col-md-2 col-lg-2'>
                                <img src={this.state.Logo} alt={this.state.RazaoSocial} title={this.state.RazaoSocial} className='w-75' />
                            </div>
                            <div className='col-md-1 col-lg-1 offset-md-8 offset-lg-9 text-center d-flex align-items-center'>
                                <a href={`/?alias=${Alias}`} role="button" onClick={LogOut} className='btn-voltar pull-rigth'>
                                    <i className="fas fa-power-off rounded-circle"></i>
                                    <span>Sair</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
                <div id="content-container" className='container flex-grow-1'>
                    <div className='row my-5'>
                        {this.props.children}
                    </div>
                </div>
                <footer className="text-center">
                    <small>Desenvolvido por <a href='https://www.geniusreturns.com.br' target='_blank' rel="noopener noreferrer"><strong>Genius Returns</strong></a></small>
                </footer>
            </>
        )
    }
}