import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'reactstrap'

import { SetAlias, SetConfig } from '../../../utils/Usuario'
import { RequestGetOpcoes, ObjetoParaQueryString } from '../../../utils/Request'

import Loading from '../../Loading'

const Alias = () => {
    const { aliasName } = useParams()
    const [erro, setErro] = useState(false)

    useEffect(() => {
        const params = {
            alias: aliasName
        }

        fetch('/api/login/ObterConfiguracao?' + ObjetoParaQueryString(params)
            , RequestGetOpcoes)
            .then(response => response.json())
            .then(data => {
                if (data != null) {
                    SetAlias(aliasName)
                    SetConfig(data)
                    window.location.href = '/';
                    return
                }

                document.getElementById('loading').classList.add('hide');
                setErro(true)
            })
            .catch(error => {
                document.getElementById('loading').classList.add('hide');
                setErro(true)
                console.error('Ocorreu um erro: ', error);
            });
    }, [aliasName, erro])

    return (
        <div id="content-container-alias" className='container flex-grow-1'>
            {
                erro
                    ? (
                        <div className='text-center alias-container'>
                            <Container>
                                <div className='primary-box intro p-5 mx-auto mb-5'>
                                    <h1>Bem vindo(a) à área do cliente</h1>
                                    <p className='mt-5'>Iremos localizar o alias informado.</p>
                                    <div className='call-switcher mx-auto mt-5'>
                                        <div className='alert alert-danger'>
                                            O alias informado não foi encontrado.
                                        </div>
                                        <p>Verifique com a loja se o link fornecido está correto.</p>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    )
                    : ''
            }
            
            <Loading text='Carregando...' />
        </div>
    );
}

export default Alias