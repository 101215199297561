const session_processos = '_processos';

const GetProcessos = () => {
    const processos = sessionStorage.getItem(session_processos)

    if (processos != null && processos.length > 0) {
        return JSON.parse(processos)
    }

    return null
}

export const SetProcessos = (value) => {
    value = JSON.stringify(value)

    return sessionStorage.setItem(session_processos, value)
}

export const Processos = GetProcessos()