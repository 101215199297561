export const session_alias = '_alias'
export const session_email = '_email';
export const session_config = '_config';

export const Alias = sessionStorage.getItem(session_alias)
export const Email = sessionStorage.getItem(session_email)

const GetConfig = () => {
    const config = sessionStorage.getItem(session_config)

    if (config != null && config.length > 0) {
        return JSON.parse(config)
    }

    return null
}

export const Config = GetConfig()

export const SetAlias = (value) => {
    return sessionStorage.setItem(session_alias, value)
}

export const SetEmail = (value) => {
    return sessionStorage.setItem(session_email, value)
}

export const SetConfig = (value) => {
    value = JSON.stringify(value)

    return sessionStorage.setItem(session_config, value)
}