import React from 'react';

import Login from '../../components/pages/Login'

const LoginPage = () => {
    return (
        <Login />
    );
}

export default LoginPage