import React from 'react';

import MeusProcessos from '../../components/pages/MeusProcessos'

const MeusProcessosPage = () => {
    return (
        <MeusProcessos />
    );
}

export default MeusProcessosPage