export const UrlLogo = 'https://app.geniusreturns.com.br/var';
export const UrlLogoS3 = 'https://genius-app-resources.s3.us-east-2.amazonaws.com/';
export const LogoGenius = 'client-logos/2/2.png';

export const GetLogoUrl = (path) => {
    if (path.includes('client-logos')) {
        return UrlLogoS3 + path
    }

    return UrlLogo + path
}