import React from 'react';

import Alias from '../../components/pages/Alias'

const AliasPage = () => {
    return (
        <Alias />
    );
}

export default AliasPage