import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import LoginPage from './pages/Login/LoginPage'
import MeusProcessosPage from './pages/MeusProcessos/MeusProcessosPage'
import AliasPage from './pages/Alias/AliasPage'
import NotFoundPage from './pages/NotFound/NotFoundPage'

import { LayoutLogin } from './components/shared/LayoutLogin'
import { LayoutMain } from './components/shared/LayoutMain'

import './custom.css'

const App = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <LayoutLogin>
                        <Switch>
                            <Route path="/" exact component={LoginPage} />
                        </Switch>
                    </LayoutLogin>
                </Route>

                <Route path='/alias/:aliasName' component={AliasPage} />

                <Route path='/meus-processos'>
                    <LayoutMain>
                        <Switch>
                            <Route path='/meus-processos' exact component={MeusProcessosPage} />
                        </Switch>
                    </LayoutMain>
                </Route>

                <Route path="*" component={NotFoundPage} />
            </Switch>
        </Router>
    );
}

export default App