import React from 'react';

import NotFound from '../../components/pages/NotFound'


const NotFoundPage = () => {
    return (
        <NotFound />
    );
}

export default NotFoundPage