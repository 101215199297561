import React from 'react'

const BoxProcessoStatusProduto = ({ sku, processo }) => {
    console.log(sku)
    const { SkusParaTroca } = sku
    const possuiTroca = SkusParaTroca != null && SkusParaTroca.length > 0

    const produtosTroca = possuiTroca
        ? SkusParaTroca.map((sku, index) => {
            return (
                <div className="row g-0 col-md-5 col-lg-5" key={sku.skuId + index}>
                    <div className="g-0 col-lg-2 col-md-4 text-center align-items-center">
                        <img src={sku.ImagemUrl} alt={sku.SkuNome} title={sku.SkuNome} />
                    </div>
                    <div className="g-0 col-lg-10 col-md-8 pl-4 text-center align-items-center">
                        <div className="text-left">
                            <span>{sku.SkuNome}</span>
                            <br />
                            <span className="data">
                                <i><span>
                                    {
                                        processo.TransportadoraProtocolo
                                            ? `Autoriz. Postagem: ${processo.TransportadoraProtocolo}`
                                            : ''
                                    }
                                </span></i>
                            </span>
                        </div>
                    </div>
                </div>
            );
        })
        : null;

    return (
        <div className="box-processo-produtos">
            <div className="row g-0">
                <div className={possuiTroca ? "row col-md-5 col-lg-5" : "row col-md-12 col-lg-12"}>
                    <div className={`${possuiTroca ? "col-lg-2 col-md-4" : "col-lg-1 col-md-4"} align-items-center pl-2`} >
                        <img src={sku.ImagemUrl} alt={sku.SkuNome} title={sku.SkuNome} />
                    </div>
                    <div className={`${possuiTroca ? "col-lg-10 col-md-8 pl-4" : "col-lg-11 col-md-8"} align-items-center`}>
                        <div className="text-left">
                            <span>{sku.SkuNome}</span>
                            <br />
                            <span className="data">
                                <i><span>{sku.TipoDescricao}</span></i>
                            </span>
                        </div>
                    </div>
                </div>
                {
                    possuiTroca
                        ? <div className="col-md-2 col-lg-2 icon">
                            <i className="fas fa-angle-double-right"></i>
                        </div>
                        :''
                }
                {produtosTroca}
            </div>
        </div>
    );
}

export default BoxProcessoStatusProduto