import React, { Component } from 'react'
import { Container } from 'reactstrap'

import { Config } from '../../../utils/Usuario'
import { UrlLogo, UrlLogoS3, GetLogoUrl } from '../../../utils/Config'

export class LayoutLogin extends Component {

    constructor(props) {
        super(props);

        //Default o logo genius
        let logoNome = UrlLogoS3 + UrlLogo

        if (Config != null && Config.cliente != null) {
            logoNome = GetLogoUrl(Config.cliente.arquivoLogo)
        }

        this.state = {
            config: Config,
            Logo: logoNome
        };
    }

    
    render() {
        return (
            <div id="content-container" className='container flex-grow-1'>
                <div className='text-center'>
                    <div className='logo my-5'>
                        <img src={this.state.Logo} alt='' title='' />
                    </div>
                    <Container>
                        {this.props.children}
                    </Container>
                </div>
            </div>
        );
    }
}