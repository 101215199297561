import React from 'react'
import { Container } from 'reactstrap'

const NotFound = () => {
    return (
        <div id="content-container" className='container flex-grow-1'>
            <div className='text-center'>
                <div className='logo my-5'>
                    <img src='https://app.geniusreturns.com.br/var///logo//2.png' alt='Genius Returns' title='Genius Returns' />
                </div>
                <Container>
                    <div className='primary-box intro p-5 mx-auto mb-5'>
                        <h1>404 Página não encontrada!</h1>
                        <p className='mt-5'>Desculpe, a página solicitada não foi encontrada!</p>
                        <div className='call-switcher mx-auto'>
                            <button onClick={() => window.location.href = '/'}>
                                Ir para home
                            </button>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
        //<div className="container container-notfound">
        //    <div className="row">
        //        <div className="col-md-12">
        //            <div className="error-template">
        //                <h1>
        //                    Oops!</h1>
        //                <h2>
        //                    404 Página não encontrada</h2>
        //                <div className="error-details">
        //                    Desculpe, a página solicitada não foi encontrada!
        //                </div>
        //                <div className="error-actions">
        //                    <a href="/" class="btn btn-primary btn-lg">
        //                        Ir para home
        //                    </a>
        //                </div>
        //            </div>
        //        </div>
        //    </div>
        //</div>

    );
}

export default NotFound